import HeroImg from 'assets/images/response-pages/hero.svg';
import theme from 'config/theme';
import styled from 'styled-components';

const { colors, fontSizes, fontWeights, fonts, letterSpacings, lineHeight, zIndices } = theme;

export const Container = styled.div<{ color?: string }>`
	padding: 20px 10vw;
	position: relative;
	top: 0;
	display: flex;
	align-items: center;
	width: 100%;
	background-color: ${({ color }) => color || colors.blue};
	height: 90vh;
	min-height: 800px;
	@media (max-width: 900px) {
		padding: 20px;
		height: auto;
		margin-top: 88px;
		align-items: flex-start;
	}
`;

export const Header = styled.header<{ color?: string }>`
	padding: 20px 80px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: ${({ color }) => color || colors.blue};

	@media (max-width: 900px) {
		padding: 20px;
	}
`;

export const Title = styled.h2`
	color: ${colors.white};
	font-size: 5em;
	font-weight: ${fontWeights.heavy};
	line-height: ${lineHeight.snug};
	font-family: ${fonts.Gilroy};
	letter-spacing: ${letterSpacings.tight};
	z-index: ${zIndices.modal};
	max-width: 640px;
	width: 100%;

	@media (max-width: 1280px) {
		font-size: ${fontSizes.xxxlarge};
		max-width: 350px;
		margin-bottom: 10px;
	}
`;

export const ListItem = styled.li`
	margin-bottom: 10px;
`;

export const ButtonContainer = styled.div`
	margin-top: 45px;
	display: flex;
	flex-wrap: wrap;
	a {
		color: ${colors.white};
		font-weight: ${fontWeights.bold};
		margin-bottom: 10px;
		border-radius: 16px;
		border: 2px solid ${colors.white};
		transition: all 0.5s;
		padding: 10px;
		margin-right: 20px;
		&:hover {
			color: ${colors.pink};
			border-color: ${colors.pink};
		}
	}
`;

export const HeroParagraph = styled.p`
	font-size: ${fontSizes.large};
	font-weight: ${fontWeights.bold};
	color: ${colors.white};
	margin-bottom: 20px;
	max-width: 450px;
	@media (max-width: 900px) {
		max-width: none;
	}
`;

export const Img = styled.div<{ image: string }>`
	background-image: url(${({ image }) => image || HeroImg});
	height: 80vh;
	width: 30vw;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	position: absolute;
	bottom: 0;
	right: 15vw;

	@media (max-width: 900px) {
		height: 300px;
		width: 300px;
		right: 30px;
	}

	@media (max-width: 320px) {
		height: 250px;
		width: 220px;
		right: 10px;
	}
`;
