import React from 'react';

import Footer from 'components/common/footer';
import Logo from 'components/icons/logo';

import { Container, Header, HeroParagraph, Img, Title, ButtonContainer } from './styles';
import { Link } from 'react-router-dom';

interface ResponseContentProps {
	textBlocks: string[];
	title: string;
	image: string;
	color?: string;
	buttons: any[];
}

const ResponseContent = ({ title, textBlocks, image, color, buttons }: ResponseContentProps) => {
	return (
		<main>
			<Container color={color}>
				<Header color={color}>
					<Logo />
				</Header>
				<div>
					<Title>{title}</Title>
					{textBlocks.map((text: string, i: number) => (
						<HeroParagraph key={i}>{text}</HeroParagraph>
					))}
					<ButtonContainer>
						{buttons.map(({ buttonText, path }: any, i: number) => (
							<Link key={i} to={path}>
								{buttonText}
							</Link>
						))}
					</ButtonContainer>
				</div>
				<Img image={image} />
			</Container>
			<Footer />
		</main>
	);
};

export default ResponseContent;
