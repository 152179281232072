import React from 'react';

import HeroImg from 'assets/images/response-pages/hero.svg';
import ResponseContent from './ResponseContent';

const NotFound = () => {
	const textBlocks = [
		"Error code 404. We couldn't find the page you're looking for, try refreshing or the links below.",
	];
	const buttons = [
		{ buttonText: 'Home', path: '/' },
		{ buttonText: 'Search for a digs', path: '/digs-search' },
	];
	return (
		<main>
			<ResponseContent
				image={HeroImg}
				title="This is awkward!"
				textBlocks={textBlocks}
				buttons={buttons}
			/>
		</main>
	);
};

export default NotFound;
